import React from "react"
import Tooltip from "rc-tooltip"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import { ContactSection } from "../commonComponents"


type AboutCardProps = {
  title: string
  body: JSX.Element
  image: JSX.Element
}

const AboutCard = ({ title, body, image }: AboutCardProps) => {
  const titleWordsArr = title.split(" ", 2)
  const neutralWords = titleWordsArr[0]
  const coloredWords = titleWordsArr[1]
  return (
    <div className="about-mv-container-new-design">
      {image}
      <div style={{ margin: "2%" }}>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="about-mv-container-title-new-design"
        >
          <div
            style={{
              marginLeft: "8%",
            }}
          >
            <span>{neutralWords}</span>
            <span style={{ marginLeft: "3%", color: "var(--text-highlight-color)" }}>
              {coloredWords}
            </span>
          </div>
        </div>
        {body}
      </div>
    </div>
  )
}

export default function Contact() {
  const readMoreTooltip = (
    <div style={{ textAlign: "center" }}>
      “failure” -- service interruptions, performance issues <br /> and bugs --
      is inevitable. We believe it's time for XaaS <br /> customer support teams
      to apply that same spirit of <br /> innovation to reinventing how they
      support their <br />
      customers. It's time to apply machine learning and <br /> artificial
      intelligence to improve response times, <br /> eliminate manual processes
      and workflows, and better <br /> understand each customer's unique
      journey.
    </div>
  )
  return (
    <Layout
      keywords="proactive AI customer support, customer support automation, about us"
      title="Learn About IrisAgent And How It Is Revolutionizing Customer Support"
      description="IrisAgent is an AI-powered customer support automation platform that uses generative AI to reduce resolution time, respond automatically to ticket and communications."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/about/"
        />
      </Helmet>
      <section
        id="about-new-design"
        className="d-flex align-items-center"
        style={{ marginTop: "5%" }}
      >
        <div className="row">
          <div className="col-md-5" style={{ padding: 0 }}>
            <div className="about-background-left">
              <div className="about-left-line" />
              <div style={{ margin: "0 15%" }}>
                <h1 className="title-support-new-design"><span style={{ color: "var(text-highlight-color)", fontWeight: 600 }}>About Us</span></h1>
                <div className="about-description-new-design">
                  IrisAgent is an AI-powered customer support automation platform that uses generative AI to reduce resolution time, respond automatically to tickets, and enhance internal communications. It also provides a chatbot and AI answering and tagging engine that can scan your data repository to resolve customer issues without human interaction.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7" style={{ padding: 0 }}>
            <div className="about-background-right">
              <img
                className="about-image-right"
                src="/img/aboutBackground.webp"
                alt="Our Mission"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id="about-mv-new-design"
        className="section-padding-left"
        style={{ background: "#FFFFFF" }}
      >
        <div className="row my-5">
          <div className="col-md-6 card-flip-col">
            <AboutCard
              title="Our Mission"
              image={
                <img
                  className="clear-image-rendering"
                  src="/img/ourMission.webp"
                  style={{
                    width: "25%",
                  }}
                  alt="Our Mission"
                />
              }
              body={
                <div className="card-flip-text">
                  We help customer support teams improve response times, reduce escalations, and ensure customer satisfaction by using artificial intelligence to better identify the true source of issues, resolve routine issues, automate repetitive tasks, and monitor customer health.
                </div>
              }
            ></AboutCard>
          </div>
          <div className="col-md-6 card-flip-col">
            <AboutCard
              title="Our Vision"
              image={
                <img
                  className="clear-image-rendering"
                  src="/img/ourVision.webp"
                  style={{
                    width: "25%",
                  }}
                  alt="Our Vision"
                />
              }
              body={
                <div className="card-flip-text">
                  Cloud-native solutions have changed the way we live and work. Endless release cycles, patches, and updates mean “failure” – service interruptions, performance issues and bugs – is inevitable. But inefficient customer support need not be. We help teams provide outstanding support to their users.
                  {/* <span>
                    <Tooltip
                      placement="bottom"
                      overlay={readMoreTooltip}
                      arrowContent={
                        <div className="rc-tooltip-arrow-inner"></div>
                      }
                    >
                      <span
                        className="gradient-text"
                        style={{ marginTop: "3%" }}
                      >
                        Read more
                      </span>
                    </Tooltip>
                  </span> */}
                </div>
              }
            ></AboutCard>
          </div>
        </div>
      </section>

      <section
        id="about-things-new-design"
        className="d-flex align-items-center"
        style={{ background: "#F9F9F9" }}
      >
        <div className="row" style={{ margin: "5% 0" }}>
          <div
            className="col-md-5"
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10%",
              marginBottom: "7%",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <div className="about-investors-title" style={{ margin: "0" }}>
                  Let's Do
                </div>
                <div
                  className="about-investors-title"
                  style={{ color: "var(text-highlight-color)", margin: "0 0 0 10px" }}
                >
                  Great
                </div>
              </div>
              <div
                className="about-investors-title"
                style={{ color: "var(--text-highlight-color)", margin: "0" }}
              >
                Things Together
              </div>
              <div className="about-join-us">
                We are a diverse and distributed team consisting of experienced
                product and engineering leaders. We are backed by seasoned VCs
                and advisors who have led customer experience at leading
                software companies.
                <br />
                <br />
                We are hiring motivated and driven engineers and leaders to join
                our growing team.{" "}
                <a href="mailto:team@irisagent.com"> Contact us</a> or apply
                directly below
              </div>
              <div style={{ paddingTop: "5%" }}>
                <a
                  href="https://angel.co/company/iris-agent/jobs"
                  className="get-started-btn scrollto"
                  target="_blank"
                  style={{ marginLeft: 0, margin: "3% 0" }}
                >
                  JOIN OUR TEAM
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-md-7"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/img/aboutCollage.webp"
              style={{
                width: "70%",
              }}
              alt="support agent"
            />
          </div>
        </div>
      </section>

      <section
        id="about-investors-new-design"
        className="align-items-center"
        style={{ marginBottom: "3%" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                display: "flex",
                marginLeft: "9%",
                marginTop: "4%",
                marginBottom: "2%",
              }}
              className="about-investors-title"
            >
              <div>Our</div>
              <div style={{ marginLeft: "1%", color: "var(--text-highlight-color)" }}>
                Investors
              </div>
            </div>
          </div>
        </div>
        <div className="web-view-new-design-display">
          <div
            className="row"
            style={{
              margin: "0 0 3% 7%",
              display: "flex",
              textAlign: "center",
            }}
          >
            <div className="about-investors-container">
              <img
                src="/img/neotribe.webp"
                style={{
                  width: "80%",
                }}
                alt="support agent"
              />
            </div>
            <div className="about-investors-container">
              <img
                src="/img/engCapital.webp"
                style={{
                  width: "70%",
                }}
                alt="support agent"
              />
            </div>
          </div>
        </div>
        <div className="mobile-view-new-design-display">
          <div
            className="about-investors-container"
            style={{ height: "106px", width: "95%", margin: "2%" }}
          >
            <img
              src="/img/neotribe.webp"
              style={{
                width: "70%",
              }}
              alt="support agent"
            />
          </div>
          <div
            className="about-investors-container"
            style={{ height: "106px", width: "95%", margin: "2%" }}
          >
            <img
              src="/img/engCapital.webp"
              style={{
                width: "70%",
              }}
              alt="support agent"
            />
          </div>
        </div>
      </section>

      <ContactSection />
    </Layout>
  )
}
